// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'
import omit from 'lodash/omit'
import map from 'lodash/map'
import { diff as diffFX, addedDiff as addedDiffFX } from 'deep-object-diff'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Notice */
class Notice extends React.Component {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [componentDidUpdate description] */
  componentDidUpdate(prevProps) {
    const { noticeQueueState: thatNoticeQueueState } = prevProps
    const { noticeQueueState: thisNoticeQueueState } = this.props
    const diff = diffFX(thatNoticeQueueState, thisNoticeQueueState)
    const diffLength = Object.keys(diff).length

    // Something changed
    if (diffLength !== 0) {
      // Find out what was added
      const added = addedDiffFX(thatNoticeQueueState, thisNoticeQueueState)
      const { notification, popNotice } = this.props

      map(added, (item) => {
        notification.open({
          className: 'notice mask-p',
          message: item.message,
          description: item.description,
          placement: 'bottomRight',
        })
        popNotice(item)
      })
    }
  }

  /** [render description] */
  render() {
    return <Fragment />
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Notice
