// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import compose from 'recompose/compose'
// import { connect } from 'react-redux'
import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import isUndefined from 'lodash/isUndefined'

import classnames from 'classnames'

import map from 'lodash/map'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import matches from 'lodash/matches'
import kebabCase from 'lodash/kebabCase'
import toNumber from 'lodash/toNumber'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import about from '../../seo/about.json'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    nav: allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          position
          routeSlug
          title {
            lang
            content
          }
        }
      }
    }
    refs: allResources(filter: { routeSlug: { eq: "/unlinked-index" } }) {
      edges {
        node {
          indexRefs {
            routeSlug
            index
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** makeTree */
const makeTree = (children, uri, originalPath, expanded, expand) => (
  <Fragment>
    {map(children, (child) => {
      if (child.routeSlug !== expanded) {
        child.children = undefined
      }

      return (
        <li
          className={classnames({
            expanded: child.routeSlug.includes(expanded),
          })}
        >
          {isUndefined(child.children) === true && (
            <Link
              to={child.routeSlug}
              className={
                endsWith(uri, child.routeSlug) ||
                endsWith(uri, `${child.routeSlug}/`)
                  ? 'active'
                  : 'passive'
              }
            >
              {child.intlTitle}
            </Link>
          )}
          {isUndefined(child.children) === false && (
            <Fragment>
              {child.children.length !== 0 && (
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    expand(child.routeSlug)
                  }}
                  className={
                    endsWith(uri, child.routeSlug) ||
                    endsWith(uri, `${child.routeSlug}/`)
                      ? 'active'
                      : 'passive'
                  }
                >
                  {child.intlTitle}
                </Link>
              )}
              {child.children.length === 0 && (
                <Link
                  to={child.routeSlug}
                  className={
                    endsWith(uri, child.routeSlug) ||
                    endsWith(uri, `${child.routeSlug}/`)
                      ? 'active'
                      : 'passive'
                  }
                >
                  {child.intlTitle}
                </Link>
              )}
            </Fragment>
          )}
          <Fragment>
            {isUndefined(child.children) === false && (
              <Fragment>
                {child.children.length !== 0 && (
                  <ul>
                    {makeTree(
                      child.children,
                      uri,
                      originalPath,
                      expanded,
                      expand
                    )}
                  </ul>
                )}
              </Fragment>
            )}
          </Fragment>
        </li>
      )
    })}
  </Fragment>
)

/** TableOfContents */
const TableOfContents = ({ uri, originalPath, expanded, expand, lang }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        nav: { edges: navEdges },
        refs: { edges: refEdges },
      } = data

      const nodes = map(navEdges, 'node').slice(0, -2)
      const intlNodes = map(nodes, (node) => ({
        intlTitle: filter(node.title, ['lang', lang])[0].content,
        position: node.position,
        routeSlug: node.routeSlug,
      }))
      const nodePositions = map(
        Object.keys(groupBy(map(intlNodes, 'position'), Math.floor)),
        toNumber
      )
      const { indexRefs } = refEdges[0].node

      const treeData = {
        intlTitle: about.altTitle,
        key: '0-0-0',
        children: [],
      }

      map(nodePositions, (nodePosition, index) => {
        const parentIndex = index
        const node = filter(intlNodes, matches({ position: nodePosition }))[0]
        const sectionRefs = find(indexRefs, ['routeSlug', node.routeSlug])

        const treeNode = {
          intlTitle: `${node.intlTitle}`,
          key: `0-${node.position}-0`,
          children: [],
          routeSlug: node.routeSlug,
        }

        map(sectionRefs.index, (ref, index) => {
          treeNode.children.push({
            intlTitle: ref,
            key: `0-${parentIndex}-${index}`,
            routeSlug: `${node.routeSlug}#${kebabCase(ref)}`,
          })
        })

        treeData.children.push(treeNode)
      })

      return (
        <Fragment>
          <nav>
            <li>
              <Link to="/">{treeData.intlTitle}</Link>
            </li>
            {makeTree(treeData.children, uri, originalPath, expanded, expand)}
          </nav>
          <ul className="etc">
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/buy-or-contribute">Buy or Contribute</Link>
            </li>
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
          </ul>
        </Fragment>
      )
    }}
  />
)

/** Header */
class Header extends React.PureComponent {
  /** [constructor description] */
  constructor(props) {
    super(props)

    const { uri, nodes, originalPath } = this.props
    const match = filter(nodes, ({ routeSlug }) => endsWith(uri, routeSlug))
    let expanded = originalPath

    if (isUndefined(match[0]) === false) {
      expanded = match[0].routeSlug
    }

    this.state = {
      isActive: false,
      whatsActive: 'root',
      expanded,
    }

    this.update = this.update.bind(this)
    this.expand = this.expand.bind(this)
    this.linkRef = React.createRef()
  }

  /** [update description] */
  update(isActive, whatsActive) {
    this.setState({ isActive, whatsActive })
  }

  /** [update description] */
  expand(expanded) {
    this.setState({ expanded })
  }

  /** [render description] */
  render() {
    const { uri, lang } = this.props
    const { isActive, whatsActive, expanded } = this.state

    return (
      <div className="fixed">
        <TableOfContents
          uri={uri}
          lang={lang}
          expanded={expanded}
          expand={this.expand}
        />
      </div>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header
